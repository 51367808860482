import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [imageSrc, setImageSrc] = useState(null);
  const [lastClickedButton, setLastClickedButton] = useState(null);

  const fetchImage = () => {
    fetch('https://danstestprep.com:3003/images')
      .then(response => response.blob())
      .then(blob => {
        const imageLocalUrl = URL.createObjectURL(blob);
        setImageSrc(imageLocalUrl);
        setAnswer(null);
        setLastClickedButton(null);
      })
      .catch(error => console.error(error));
  };

  const [answer, setAnswer] = useState(null);

  const fetchAnswer = (option) => {
    fetch(`https://danstestprep.com:3003/answers`)
      .then(response => response.text())
      .then(data => {
        setAnswer(data);
      })
      .catch(error => console.error(error));
  };

  const handleButtonClick = (option) => {
    setLastClickedButton(option);
    fetchAnswer();
    if (answer === null) {
      setAnswer(option);
    }
  };

  return (
    <div className="App" style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ width: '80%' }}>
        <h1 className="app-title">ShayanPrep</h1>
        {imageSrc && <img src={imageSrc} alt="Randomly selected file" style={{ width: '100%', height: 'auto' }} />}
        <div className="button-container">
          <button className={`answer-button ${lastClickedButton === 'A' ? 'clicked-button' : ''}`} onClick={() => handleButtonClick('A')} disabled={answer !== null}>A</button>
          <button className={`answer-button ${lastClickedButton === 'B' ? 'clicked-button' : ''}`} onClick={() => handleButtonClick('B')} disabled={answer !== null}>B</button>
          <button className={`answer-button ${lastClickedButton === 'C' ? 'clicked-button' : ''}`} onClick={() => handleButtonClick('C')} disabled={answer !== null}>C</button>
          <button className={`answer-button ${lastClickedButton === 'D' ? 'clicked-button' : ''}`} onClick={() => handleButtonClick('D')} disabled={answer !== null}>D</button>
        </div>
        {answer && <p>The answer is {answer}</p>}
        {lastClickedButton && <p>You chose {lastClickedButton}</p>}
      </div>
      <div style={{ width: '20%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <p>Question Type</p>
        <button className="new-question-button" onClick={fetchImage}>New Question</button>
      </div>
    </div>
  );
}

export default App;

